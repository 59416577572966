.ticket-card{
    border: 1px solid #ccc;
    padding: 40px;
}

.ticket-form{
    display: flex;
    gap: 20px;
    margin-top: 20px;
    
}
.search-left{
    width: 70%;
    display: flex;
    gap: 20px;
    align-items: center;
}

.form-control {
    width: 100%;
}

.input-right{
    width:30%;
    display: flex;
    gap: 20px;
}

.form-control input {
    text-indent: 7px;
}

.booking-order {
    display: flex;
    justify-content: end;
    gap: 10px;
    align-items: center;

}

.form-count input{
    border: none;
}

.form-count {
    width: 14px;
}


.btn-wrp{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 33px;
}

.btn-wrp button {
    padding: 14px 73px;
}
.add-main-wrp {
    padding: 20px;
}

.done-btn button{
    margin-top: 20px;
    width:100%;
    padding: 8px;

}

.add-memb-wrp{
    display: flex;
    align-items: center;
    width: 260px;
   justify-content: space-between;
}

.add-icon-wrp{
    gap: 10px;
    display: flex;
    align-items: center;
}

.add-childer{
    margin-top: 20px 
}

.child-wrp{
    margin-top: 10px;
}

.child-inner-wrp{
    display: flex;
}

.inner-pop {
    width: 591px;
    height: 800px;
}

.close-btn{
    display: flex;
    /* justify-content: end; */
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;

}
.form-data {
    padding: 30px;
    line-height: 28px;
}

.form-data h4 {
    color: #707370;
}