.landing-page {
    display: flex;
    width: 100%;
    min-height: 100vh;
    .sidemenu {
        width: 16%;
        transition: 0.5s;
        background: antiquewhite;
        display: flex;
        justify-content: center;
    }
    .hide-side-menu {
        width: 50px;
        transition: 0.5s;
        background: antiquewhite;
        display: flex;
        justify-content: center;
        .brand-logo {
            height: 40px;
            width: 150px;
        }
    }
    .page-layout {
        width: 100%;
        transition: 0.5s;
    
    .page-content {
        padding: 45px;
    }
}

.full-page-layout {
    width: 100%;
    transition: 0.5s;
    .page-content {
        padding: 20px;
    }
}
}
@media (max-width: 769px) {
    .sidemenu{
        width: 250px !important;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 10;
        overflow: hidden;
        .overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            display: block;
            z-index: 0;
        }
        .side-menu{
            z-index: 1;
            background-color: antiquewhite;
        }
    }
    .hide-side-menu{
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        z-index: 1;
        width: 0 !important;
        overflow: hidden;
    }
    .page-layout{
        width: 100% !important;
    }
}