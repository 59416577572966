$poorvika-primary: #fb6c00;
$poorvika-shadow: 0px 1px 5px rgb(4 11 23 / 4%), 0px 5px 25px rgb(0 0 0 / 6%), 0px 0px 2px rgb(0 0 0 / 9%);
$poorvika-offers-fonts: 'Inter', sans-serif;
$disabled-state: #b2b5ba38;
$color-error: #ed240f;

.hide-element {
    display: none;
}

.no-width {
    width: 0%;
}

.page-heading {
    margin: 0;
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
p {
    font-family: 'Inter', sans-serif;
    margin: 0;
}

.shadow {
    box-shadow: $poorvika-shadow;
}

.form-group {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    label {
        font-size: 13px;
    }
}

.emi-wrapper {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
}


table {
    text-align: center;
    width: 100%;
    border-radius: 5px;
    margin-top: 5px;
    border-collapse: collapse;

    thead,
    tbody {
        tr {
            border-bottom: 1px solid #9e9e9e5c;
            th {
                padding: 12px 15px;
                overflow: hidden;
                max-width: 155px;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                
            }
            td {
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #696767;;
            }
        }
        tr:hover {
            background: #9e9e9e17;
        }
    }
}

.error-text {
    font-size: 10px;
    margin-top: 5px;
    padding-left: 5px;
    color: #ed240f;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid #c5c9d1 !important;
}

.Mui-focused {
   color: #746e6e !important;
}

.MuiTextField-root {
    background: #fafbfb !important;
}

.MuiInputBase-input {
    font-size: 14px !important;
    height: 25px !important;
}