.app-header {
    display: flex;
    align-items: center;
    color: #FFF;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 11px;

    

    .icons-wrapper {
        display: flex;
        gap: 20px;
        font-size: 20px;

        .logout-button {
            position: relative;
            p {
                position: absolute;
                background: #0000006e;
                font-size: 12px;
                right: 0;
                padding: 5px 10px;
                border-radius: 5px;
                top: 15px;
                display: none;
            }
            
        }
        &:hover {
            p{
                display: block !important;
            }
        }

        .action-icon {
            cursor: pointer;
        }
    }
}
    