body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.dflex{
  display: flex;
}

.items-center{
  align-items: center;
}

.justify-between{
  justify-content: space-between;
}

.parm_wrp h3{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #000000;
  margin-top: 20px;
    margin-bottom: 20px;
}
.list_wrp{
  justify-content: space-between;
}
.divd_wrp{
  margin-top:20px;
  margin-bottom:20px;
}

.list_width{
  width:20%;
}

.divider{
    margin: 0px;
    flex-shrink: 0;
    border-width: 0px 0px thin;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
}

.list_wrp h4{
  color: #626D82;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0px;
}


.list_wrp h6{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 20px;
color: #000000;
margin: 0px;
}


.inner_list{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.inner_list h4{
  line-height: 27px;
}

.cursor-pointer {
  cursor: pointer
}